<template>
  <div>
    <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
      {{ text_snackbar }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="600px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="title_wrapper">
            <div class="title_left">
              <span class="text-h5">{{ formTitle }}</span>
            </div>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-select
                    v-bind:label="`${$t('Year')}`"
                    v-model="selected.year"
                    :items="year_list"
                    :hide-details="true"
                    filled
                    required
                    :rules="requiredRules"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select
                    v-bind:label="`${$t('Month')}`"
                    v-model="selected.month"
                    item-text="name"
                    item-value="id"
                    :items="month_list"
                    :hide-details="true"
                    filled
                    required
                    :rules="requiredRules"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="selected.userId"
                    v-bind:label="$t('User')"
                    item-text="name"
                    item-value="id"
                    :items="filtered_users"
                    :search-input.sync="search_users"
                    :loading="isUserSearching"
                    hide-no-data
                    hide-selected
                    filled
                    required
                    :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <vuetify-money
                    v-model="selected.totalAmount"
                    v-bind:label="`Total ${$t('amount')}`"
                    v-bind:options="currency_options"
                    required
                    :rules="totalAmountRules"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="isSaving"
              @click="onSaveClick(selected)"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <h4>{{ this.$t("Solred") }}</h4>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="text-right">
            <v-btn
              v-if="importExcelTemplatePathName"
              color="blue darken-1"
              @click="downloadExcelTemplate(importExcelTemplatePathName)"
            >
              <b-icon icon="download" aria-hidden="true"></b-icon> Plantilla
              Import
            </v-btn>
            <v-btn
              color="success"
              dark
              class="ml-2"
              :loading="isSelecting"
              @click="onUploadExcelClick"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              {{ buttonText }}
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept=".xls,.xlsx"
              @change="onFileChanged"
            />
            <v-btn color="warning" dark class="ml-2" @click="downloadExcel()">
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12" sm="12" md="12">
            <ve-table
              id="solred-table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="solred_columns"
              :table-data="tableData"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaltableData"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import moment from "moment";
import _ from "lodash";
import download from "js-file-download";

export default {
  name: "Solred",
  components: {},
  data() {
    return {
      importExcelTemplatePathName: null,
      currency_options: {
        locale: "pt-BR",
        prefix: "€",
        suffix: "",
        length: 11,
        precision: 2
      },
      user_roles: [],
      tableData: [],
      totaltableData: 0,

      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterCriteria: {
        year: {},
        month: {},
        username: {},
        employee: {},
        usertype: {},
        parentId: {},
        totalAmount: {}
      },
      filterable_col_list: [
        "year",
        "month",
        "username",
        "employee",
        "usertype",
        "parentId",
        "totalAmount"
      ],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        },
        bodyCellClass: ({ row, column, rowIndex }) => {
          if (row.approvalStatus === "Aprobado") {
            return "table-body-cell-approved";
          } else if (row.approvalStatus === "Incidencia") {
            return "table-body-cell-incidence";
          }
        }
      },
      showEmpty: false,

      /********** form-validation options **********/
      valid: true,
      requiredRules: [v => !!v || "Required"],
      totalAmountRules: [
        v => !!v || "Required",
        v =>
          (this.selected.totalAmount && this.selected.totalAmount >= 0) ||
          "Este campo debe estar por encima de 0"
      ],
      /********** create or edit dialog options **********/
      showEdit: false,
      selected: null,
      editedIndex: -1,
      search_users: null,
      isUserSearching: false,
      filtered_users: [],
      default_routes: [],
      select_date_menu: false,
      nowDate: new Date().toISOString().slice(0, 10),
      reactive: false,
      current_approvalStatus_class: "error",
      isSaving: false,
      selectable_mindate: new Date().toISOString().slice(0, 10),
      isDeleting: false,
      month_list: [
        { id: 1, name: "ENE" },
        { id: 2, name: "FEB" },
        { id: 3, name: "MAR" },
        { id: 4, name: "ABR" },
        { id: 5, name: "MAY" },
        { id: 6, name: "JUN" },
        { id: 7, name: "JUL" },
        { id: 8, name: "AGO" },
        { id: 9, name: "SEPT" },
        { id: 10, name: "OCT" },
        { id: 11, name: "NOV" },
        { id: 12, name: "DIC" }
      ],
      year_list: [],

      /********** others **********/
      isSelecting: false,
      selectedFile: null,
      defaultButtonText: "Import Excel",
      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple"
    };
  },
  watch: {
    $route(to, from) {
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        this.filterCriteria[key] = Object.assign({});
      }
      this.getDataFromApi();
    },
    async search_users(val) {
      await this.search_user_list(val);
    }
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated", "isGPV", "user"]),
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? ""
        : this.$t("edititem");
    },
    buttonText() {
      return this.isSelecting ? "Uploading..." : this.defaultButtonText;
    },
    noDataPending() {
      const {
        date,
        routeId,
        startKM,
        endKM,
        startPhoto,
        endPhoto,
        current_selected_date_data_id,
        id,
        is_already_exist,
        isChecking
      } = this.selected;
      if (
        date &&
        startKM >= 0 &&
        endKM >= 0 &&
        (startPhoto.img || startPhoto.static) &&
        (endPhoto.img || endPhoto.static) &&
        (id === current_selected_date_data_id || !is_already_exist)
      ) {
        if (!isChecking && this.isGPV && !routeId) {
          return false;
        }
        return true;
      }
      return false;
    },
    col_year() {
      return {
        key: "year",
        title: this.$t("Year"),
        field: "year",
        align: "left",
        width: 100,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["year"]["from"]}
                  value={this.filterCriteria["year"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["year"]["to"]}
                  value={this.filterCriteria["year"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "year")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_month() {
      return {
        key: "month",
        title: this.$t("Month"),
        field: "month",
        align: "left",
        width: 100,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["month"]["from"]}
                  value={this.filterCriteria["month"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["month"]["to"]}
                  value={this.filterCriteria["month"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "month")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_username() {
      return {
        key: "username",
        title: this.$t("salesforce.UserName"),
        field: "username",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.user && row.user.username}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["username"]}
                  apiUrl={`expenses/solred/getfilterlist`}
                  columnName={`username`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["username"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "username")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_employee() {
      return {
        key: "employee",
        title: this.$t("Employee"),
        field: "employee",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <span>{row.user && `${row.user.name} ${row.user.surname}`}</span>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["employee"]}
                  apiUrl={`expenses/solred/getfilterlist`}
                  columnName={`employee`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["employee"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "employee")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_usertype() {
      return {
        key: "usertype",
        title: this.$t("salesforce.user_type"),
        field: "usertype",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.user && row.user.role}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["usertype"]}
                  dataList={this.user_roles}
                  filterCriteria={this.filterCriteria["usertype"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "usertype")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_parentId() {
      return {
        key: "parentId",
        title: this.$t("expensesParentUserName"),
        field: "parentId",
        align: "left",
        width: 180,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <span>
              {row.user && row.user.Parent && row.user.Parent.username}
            </span>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["parentId"]}
                  apiUrl={`expenses/solred/getfilterlist`}
                  columnName={`parentId`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["parentId"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "parentId")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_total_amount() {
      return {
        key: "totalAmount",
        title: "Total " + this.$t("amount"),
        field: "totalAmount",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{this.currency_format(row.totalAmount)}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["totalAmount"]["from"]}
                  value={this.filterCriteria["totalAmount"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["totalAmount"]["to"]}
                  value={this.filterCriteria["totalAmount"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "totalAmount")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_edit() {
      return {
        key: "edit",
        title: this.$t("salesforce.Edit"),
        field: "actions",
        align: "center",
        width: 100,
        fixed: "right",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              <v-icon
                medium
                color="primary"
                class="mr-2"
                on-click={() => this.onItemEdit(row)}
              >
                mdi-pencil
              </v-icon>
              <v-icon
                medium
                color="error"
                on-click={() => this.onItemRemove(row.id)}
              >
                mdi-delete
              </v-icon>
            </div>
          );
        }
      };
    },
    solred_columns() {
      return [
        this.col_year,
        this.col_month,
        this.col_username,
        this.col_employee,
        this.col_usertype,
        this.col_parentId,
        this.col_total_amount,
        this.col_edit
      ];
    }
  },
  methods: {
    async search_user_list(val) {
      if (!val) val = "";
      this.isUserSearching = true;
      let fetch_url = `expenses/solred/getfilterlist?column=userlist&filterValue=${val}`;
      const resp = await ApiService.get(fetch_url);
      this.filtered_users = resp.data;
      this.isUserSearching = false;
      if (this.filtered_users.length === 0) {
        this.filtered_users = this.default_routes;
      }
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#solred-table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      let filter_columns = document.querySelectorAll(
        "#solred-table .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      if (this.isteam !== undefined) {
        query_params_string += "&isteam=" + this.isteam;
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "expenses/solred";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const resp = await ApiService.get(fetch_url);
        this.tableData = resp.data;
        this.showEmpty = true;
        if (this.tableData.length > 0) {
          this.showEmpty = false;
        }
        this.totaltableData = resp.total;
        this.user_roles = resp.user_roles;
        this.importExcelTemplatePathName = resp.importExcelTemplatePathName;
        this.close();
      } catch (error) {
        this.close();
      }
    },
    onItemEdit(item) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },
    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    createSelectedModel(item) {
      let model = {
        id: _.get(item, "id"),
        year: _.get(item, "year"),
        month: _.get(item, "month"),
        userId: _.get(item, "userId"),
        user: _.get(item, "user"),
        totalAmount: _.get(item, "totalAmount"),
        lastPendingAmount: _.get(item, "lastPendingAmount"),
        calcedAmount: _.get(item, "calcedAmount")
      };
      if (model.user !== undefined) {
        this.filtered_users = [model.user];
      }
      console.log(model);
      return model;
    },
    async onSaveClick(item) {
      if (this.$refs.form.validate()) {
        try {
          this.isSaving = true;
          let body = _.omit(item, ["user"]);
          if (body.id) {
            await ApiService.put(`expenses/solred/${body.id}`, body);
          }
          logInfo("Éxito !");
          this.showEdit = false;
          this.getDataFromApi();
          this.isSaving = false;
        } catch (error) {
          this.isSaving = false;
        }
      }
    },
    async downloadExcel() {
      this.show();
      let query_params_string = this.getQueryParams();
      console.log(query_params_string);
      let fetch_url = "expenses/solred/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const response = await ApiService.get(fetch_url, {
        responseType: "blob"
      });
      download(response, "solred.xlsx");
      this.close();
    },
    init() {
      this.getDataFromApi();
    },
    onItemRemove(item_id) {
      if (window.confirm(this.$t("confirmdelete"))) {
        ApiService.delete(`expenses/solred/${item_id}`).then(() => {
          logInfo("eliminadas");
          this.getDataFromApi();
        });
      }
    },
    onUploadExcelClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: false }
      );
      this.$refs.uploader.click();
    },
    async onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      if (window.confirm(`Are you sure you want to upload this excel-file?`)) {
        this.isSelecting = true;
        let data = new FormData();
        data.append("file", this.selectedFile);
        this.$refs.uploader.value = "";
        const response = await ApiService.post(
          `expenses/solred/uploadexcel`,
          data,
          {
            headers: {
              "Content-Type": `multipart/form-data;`
            }
          }
        );
        const {
          success,
          insertedRowCount,
          updatedRowCount,
          failedRows,
          invalidFile,
          message
        } = response;
        console.log(updatedRowCount);
        this.snackbar_class = "deep-purple";
        if (success && (insertedRowCount > 0 || updatedRowCount > 0)) {
          this.text_snackbar = `${insertedRowCount} new row${
            insertedRowCount > 1 ? "s" : ""
          } / ${updatedRowCount} row${
            updatedRowCount > 1 ? "s" : ""
          } updated !`;
          this.snackbar = true;
        } else if (failedRows) {
          this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
          this.snackbar = true;
          this.exportCSVfileGlobal(response.titles, response.failedRows);
        } else if (!invalidFile && message) {
          this.text_snackbar = message;
          this.snackbar_class = "red";
          this.snackbar = true;
        }
        this.isSelecting = false;
        this.getDataFromApi();
      }
    },
    async downloadExcelTemplate(downloadFile) {
      let downloadfile = ApiService.getExcelTemplateFileUri(downloadFile);
      try {
        const response = await ApiService.get(downloadfile, {
          responseType: "arraybuffer"
        });
        download(response, downloadFile);
      } catch (error) {
        // this.close();
      }
    }
  },
  mounted() {
    let cur_date = new Date();
    let cur_year = cur_date.getFullYear();
    for (let i = cur_year; i >= cur_year - 10; i--) {
      this.year_list.push(i);
    }
    this.init();
  }
};
</script>

<style>
.status_wrapper {
  text-align: center;
}
.status_wrapper .row {
  margin-top: 0;
  color: #000;
}
#solred-table tbody img {
  width: 100% !important;
}
input[type="date"]:before {
  color: lightgrey;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}
input[type="date"]:focus:before {
  content: "" !important;
}
input.filter_date_picker {
  width: 100%;
  border: 1px solid #999;
  margin-top: 10px;
}
.lightbox_image_item {
  max-width: 100%;
  height: 300px;
  cursor: pointer;
  background-position: center;
  background-size: contain;
}
.title_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title_wrapper .btn_status {
  cursor: initial;
}
.picture-inner,
.picture-preview {
  z-index: 1000 !important;
}
.error--text input[type="number"] {
  color: #f00;
}
</style>
